'use strict';

var UserPasswordPopup = {
  _pwd_pop_content: {},
  _pwd_err: 0
};

UserPasswordPopup._pwd_validation = function ($field) {
  var msg = [];
  var v = $field.val();

  if (new RegExp("(?=.*\\d)").test(v)) {
    msg.push(['good', 'One digit from 0-9']);
  } else {
    msg.push(['bad', 'One digit from 0-9']);
  }

  if (new RegExp("(?=.*[a-z])").test(v)) {
    msg.push(['good', 'One lowercase character']);
  } else {
    msg.push(['bad', 'One lowercase character']);
  }

  if (new RegExp("(?=.*[A-Z])").test(v)) {
    msg.push(['good', 'One uppercase character']);
  } else {
    msg.push(['bad', 'One uppercase character']);
  }

  if (new RegExp("(?=.*[!@#$%^&*()_+;',.])").test(v)) {
    msg.push(['good', 'One symbol !@#$%^&*()_+;\',.']);
  } else {
    msg.push(['bad', 'One symbol !@#$%^&*()_+;\',.']);
  }

  if (new RegExp(".{6,20}").test(v)) {
    msg.push(['good', 'Minimum of 6 and maximum of 20 characters']);
  } else {
    msg.push(['bad', 'Minimum of 6 and maximum of 20 characters']);
  }
  //aA$4d
  //console.log(msg.length);
  //var popContent = $field.parent().parent().find('#popover-content');
  //console.log(msg);
  //var m = [];
  var m = $('<div id="popover-content">');
  if (msg.length > 0) {
    UserPasswordPopup._pwd_err = 0;
    //console.log(msg); return;
    $.each(msg, function (key, value) {
      var icon = "";

      if (value[0] === 'bad') {
        //console.log(value[1]);
        icon = "<i class='fa fa-remove'></i>";
        UserPasswordPopup._pwd_err = UserPasswordPopup._pwd_err + 1;
      }
      if (value[0] === 'good') {
        icon = "<i class='fa fa-check'></i>";
      }
      //m.push('<span class="' + value[0] + '">' + icon + value[1] + '</span>');
      // m.append('<span class="' + value[0] + '">' + icon + value[1] + '</span>');
      m.append('<div class="' + value[0] + '">' + icon + '<span>' + value[1] + '</span></div>');
    });
    msg = [];
    //popContent.html(m);
    //return m;
  }
  UserPasswordPopup._pwd_pop_content = m;
};

/**
 * not required
 * @private
 */
UserPasswordPopup._pwd_popup_content = function () {
  var msg = {
    'digit': { 'class': 'good', 'msg': 'One digit from 0-9' },
    'lowercase': { 'class': 'good', 'msg': 'One lowercase character' },
    'uppercase': { 'class': 'good', 'msg': 'One uppercase character' },
    'symbol': { 'class': 'good', 'msg': 'One symbol !@#$%^&*()_+;\',.' },
    'Minimum': { 'class': 'good', 'msg': 'Minimum of 6 and maximum of 20 characters' }
  };

  var m = $('<div id="popover-content">');

  $.each(msg, function (rule, value) {
    icon = "<i class='fa fa-check'></i>";
    m.append('<div class="' + value.class + '">' + icon + '<span>' + value.msg + '</span></div>');
  });
  msg = [];
  //popContent.html(m);
  //return m;
  UserPasswordPopup._pwd_pop_content = m;
};

UserPasswordPopup._pwd_popup_instance = function (value) {
  return $('#UserPassword').popover({
    //selector: '#aUserPassword',
    container: 'body',
    trigger: 'manual',
    placement: 'top',
    html: true,
    animation: false,
    title: '<span>Password must contains at least:</span>',
    //<button type="button" id="close" class="close">&times;</button>
    content: function content() {
      //return $("#popover-content").html();
      return UserPasswordPopup._pwd_pop_content.html();
    },
    delay: { show: 0, hide: 0 }
  });
};
UserPasswordPopup._pwd_popup = function (value, $field) {
  //$('#UserPassword').on('input', function () {
  //  var $field = $(this);
  UserPasswordPopup._pwd_validation($field);

  var $p = UserPasswordPopup._pwd_popup_instance();
  //console.info(UserPasswordPopup._pwd_err);
  var $fv = $('#profileForm');
  //$fv.formValidation('revalidateField', 'notEmpty');
  /**
   * just show them the window about what is accepted for special symbols at the beginning
   */
  $p.popover('show');
  if (UserPasswordPopup._pwd_err > 0) {
    $p.popover('show');
    //$fv.formValidation('enableFieldValidators', 'data[User][password]', true, 'regexp');
  } else {
    $p.popover('dispose');
    //$fv.formValidation('enableFieldValidators', 'data[User][password]', false, 'regexp');
  }
  //$fv.formValidation('revalidateField', 'regexp');
  //});
};