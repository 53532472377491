"use strict";

/*
 Author Harvinder Deogan
 www.HarvinderDeogan.com
 mail@HarvinderDeogan.com
 */
jQuery.fn.shake = function (intShakes, intDistance, intDuration) {
  this.each(function () {
    $(this).css("position", "relative");
    for (var x = 1; x <= intShakes; x++) {
      $(this).animate({ left: intDistance * -1 }, intDuration / intShakes / 4).animate({ left: intDistance }, intDuration / intShakes / 2).animate({ left: 0 }, intDuration / intShakes / 4);
    }
  });
  return this;
};

// $("#shaker").click(function(){
// 	$("#shaker").shake(2,7,800);
// });

function termsPopup() {
  window.open('/uploads/terms-and-conditions.pdf', 'popup', 'width=800,height=700,scrollbars=yes,resizable=yes,toolbar=no,directories=no,location=no,menubar=no,status=no,left=70,top=70');
}

function loading() {
  // alert("Hello World!");
  $("#loading").show();
}

function popup(url, options) {
  var window_width = $('body').width();
  var window_height = $('body').height();
  var marginLeft = (window_width - 800) / 2;
  var marginTop = (window_height - 500) / 2;
  console.log(marginTop);
  var _options = {
    width: 800,
    height: 500,
    scrollbars: 'yes',
    resizable: 'yes',
    toolbar: 'no',
    directories: 'no',
    location: 'no',
    menubar: 'no',
    status: 'no',
    left: marginLeft,
    top: marginTop
  };
  options = $.extend(_options, options);
  var output = [];
  $.each(options, function (index, value) {
    // console.log(index + "~" + value);
    output.push(index + '=' + value);
  });
  output = output.join(',');
  // console.log(output);
  // window.open(URL,name,specs,replace)
  window.open(url, '_blank', output, false);
  return false;
}