'use strict';

/*jshint esversion: 6 */
$ = jQuery;
var App = {
  common: {
    init: function init() {
      interactive.init();
      App.common._search();
      App.common._tooltip();
      App.common._popover();
      App.common._nav();
      App.common._testimonialsSlider();
      App.common._fancybox();
      // App.common.flashMessage();
      App.common.matchHeightResponsive();
      $(window).resize(function () {
        App.common.matchHeightResponsiveReset();
        App.common.matchHeightResponsive();
      });
      /**
       * home page
       */
      $('body').on('click', '#learn-more', function (event) {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: $('section#how-it-works').offset().top
        }, 1000);
        //$contact_us.find('.head').trigger('click');
      });

      // application-wide code
      $('.menu li a.selected').parents('li').addClass('parent');

      try {
        //do not use selectize due to a bug on page http://loc.sparreyconsulting.com/career-pursuit-profile/minimum-requirements/save
        $(".selectize select").selectize();

        /**
         * vertically-center
         */
        $('.vertically-center').flexVerticalCenter();

        /**
         * match-height
         * using this way does not _update on resize of window
         */
        $('.match-height').each(function () {
          $(this).find('> *').matchHeight({ property: 'min-height', byRow: false });
        });

        /**
         * a group of parent rows in table
         */
        $('.match-height-table > .row > *').matchHeight({
          byRow: true,
          property: 'height',
          target: null,
          remove: false
        });

        /**
         * a group of child rows (if any) in table
         */
        $('.match-height-table > .row > div .row > *').matchHeight({
          byRow: true,
          property: 'height',
          target: null,
          remove: false
        });
      } catch (e) {
        console.log(e.message);
      }

      if ($('.tooltip_id').length) {
        $('.tooltip_id').each(function () {
          var $this = $(this);
          $this.tooltip({
            // trigger:'click',
            trigger: 'hover',
            // delay: { show: 500, hide: 200 },
            container: 'body',
            placement: 'top',
            html: true
            // content: $this.find('.hidden').html()
          });
        });
      }
      //closePopoversOnDocumentEvents();
      App.common.disableButtonOnFormSubmit();
      App.common.readmoreJs();
    },
    matchHeightResponsiveReset: function matchHeightResponsiveReset() {
      $.each(['.match-height-all', '.match-height-xs', '.match-height-sm', '.match-height-md', '.match-height-lg'], function (index, _selector) {
        $(_selector).each(function () {
          $(this).find('.match-height-block').matchHeight({ property: 'min-height', byRow: false, remove: true });
          //console.log(_selector);
        });
      });
    },
    matchHeightResponsive: function matchHeightResponsive() {
      /**
       * this function allow using match-height on all 4 breakpoints
       * @type {*|jQuery}
       */

      $('.match-height-all').each(function () {
        $(this).find('.match-height-block').matchHeight({ property: 'min-height', byRow: false });
      });

      var zHeight = $('body').width();
      if (zHeight < 768) {
        $('.match-height-xs').each(function () {
          $(this).find('.match-height-block').matchHeight({ property: 'min-height', byRow: false });
          //console.log('xs');
        });
      } else if (zHeight < 992 && zHeight >= 768) {
        $('.match-height-sm').each(function () {
          $(this).find('.match-height-block').matchHeight({ property: 'min-height', byRow: false });
        });
        //console.log('sm');
      } else if (zHeight < 1200 && zHeight >= 992) {
        $('.match-height-md').each(function () {
          $(this).find('.match-height-block').matchHeight({ property: 'min-height', byRow: false });
        });
        //console.log('md');
      } else {
        $('.match-height-lg').each(function () {
          $(this).find('.match-height-block').matchHeight({ property: 'min-height', byRow: false });
        });
        //console.log('lg');
      }
      //console.log(zHeight);
    },
    _fancybox: function _fancybox() {
      $("a[href$='.jpg'],a[href$='.jpeg'],a[href$='.png'],a[href$='.gif']").attr('rel', 'gallery').fancybox();

      var a = $('.fancybox-media').fancybox();
      //var a = $('.fancybox-media').fancybox({
      //  openEffect: 'none',
      //  closeEffect: 'none',
      //  width: 800,
      //  height: 520
      //  //helpers: {
      //  //  media: {}
      //  //}
      //});
      //console.log(a);
    },
    readmoreJs: function readmoreJs() {
      $('.readmore-js').each(function () {
        $(this).readmore({
          speed: 225,
          collapsedHeight: 20 * 1.5 * 1 /*lines*/, /*in pixels*/
          moreLink: '<a href="#">Read more</a>',
          lessLink: '<a href="#">Close</a>'
        });
      });
    },
    _testimonialsSlider: function _testimonialsSlider() {
      if ($('#testimonials-slider').length) {
        $('#testimonials-slider').not('.slick-initialized').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          adaptiveHeight: true,
          cssTransitions: false,
          fade: false
        });
      }
    },
    _nav: function _nav() {
      /**
       * there is an issue on mobile
       * single click is executed twice
       */
      //$("#navbar-collapse-1").on('shown.bs.collapse', function () {
      //  console.log($(this));
      //});


      /**
       * bootstrap navbar collapse on click
       */
      //$("nav").find("li").on("click", "a", function () {
      //  $('.navbar-collapse.in').collapse('hide');
      //});

      //$('.navbar-header button').each(function () {
      //  //console.log($(this));
      //  $(this).unbind('click');;
      //});

      //unbind('click');
      //$('.navbar-header button[aria-expanded="true"]').each(function () {
      //  console.log($(this));
      //$(this).on("click", function () {

      //$(this).parent().parent().find('.navbar-collapse.in').collapse('hide');
      //});
      //});

      $(window).on("scroll", function () {
        var _element = 'body';

        //get scroll position for firefox first
        var hiddenPixels = document.documentElement.scrollTop;

        if (hiddenPixels === 0) {
          hiddenPixels = $(_element).scrollTop();
        }

        //var hiddenPixels = $(_element).scrollTop();

        //)?$(_element).scrollTop() : $(_element).scrollTop;
        //var hiddenPixels = $('body').offset().top;

        //console.log(hiddenPixels);
        //return false;
        var heightFromTop = 20;
        if ($('#hero').length) {
          heightFromTop = $('#hero').height();
        }

        if (hiddenPixels > heightFromTop) {
          $('#header').addClass('scrolled');
        } else {
          $('#header').removeClass('scrolled');
        }
      });
    },
    _tooltip: function _tooltip() {
      $('[data-toggle="tooltip"],[rel=tooltip],*[data-title]:not([data-content]),input[title],textarea[title]').tooltip();
    },
    _popover: function _popover() {
      // $('body').popover();
      $('[data-toggle="popover"], .popover_id,[rel=popover]').popover({
        trigger: 'hover',
        delay: { show: 300, hide: 200 },
        html: true
      });

      $('[rel="popover"]').each(function () {
        var $this = $(this);
        $this.popover({
          trigger: 'click',
          // trigger:'hover',
          // delay: { show: 500, hide: 200 },
          container: 'body',
          placement: 'top',
          html: true,
          content: $this.find('.hidden').html()
        });
      });
    },
    _search: function _search() {
      /**
       * there is an issue on pv pages, click executes twice
       * so unbind the event before bind it
       */
      $('.search-trigger').unbind("click").bind("click", function (event) {
        event.preventDefault();
        /**
         * in
         */
        if ($(this).find('i').length && $('#header').length) {
          //console.log('start');
          var $search = $(this).find('i');
          //$(this).closest('#header')
          var $header = $('#header');
          if ($search.hasClass('fa fa-search')) {
            //console.log('show');
            $search.removeClass('fa fa-search');
            $search.addClass('fa fa-remove');
            $header.addClass('search-open');
            $header.find('.search-wrap').find('.form-control').focus();
          } else {
            //console.log('hide');
            $search.removeClass('fa fa-remove');
            $search.addClass('fa fa-search');

            $header.removeClass('search-open');
            //$header.removeClass('shown-search-wrap');
          }
        }
      });
    },
    disableButtonOnFormSubmit: function disableButtonOnFormSubmit() {
      /*avoid users to click submit button for the pages are not fully loaded, disable submit button for 1 second*/
      $('form').find('input[type^="submit"],button[type^="submit"]').prop('disabled', true).delay(1000).queue(function (next) {
        $(this).prop('disabled', false);
        next();
      });

      /*aviod user double clicks, disable submit button for 2 second*/
      $('form').bind('submit', function () {
        $(this).find('[type^="submit"]').prop('disabled', true).delay(10000).queue(function (next) {
          $(this).prop('disabled', false);
          console.log('submit');
          next();
        });
        //return false;
      });

      $('#disableSubmit5s form').unbind('submit');
      $('#disableSubmit5s form').bind('submit', function () {
        $(this).find('input[type^="submit"],button[type^="submit"]').prop('disabled', true).delay(200000).queue(function (next) {
          $(this).prop('disabled', false);
          next();
        });
      });
    }
  },

  pluginName: {
    init: function init() {
      // application-wide code
    }
  },
  controllerName: {
    init: function init() {
      // controller-wide code
    },

    actionName: function actionName() {
      // action-specific code
    }
  },
  users: {
    init: function init() {
      // controller-wide code
      App.users._userValidation();
    },
    login: function login() {
      //console.log("hi");
      /**
       * Here how to call above plugin from everywhere in your application document body
       */
      $.toggleShowPassword({
        field: '#UserPassword',
        control: '#UserShowPassword'
      });
    },
    //
    _userValidation: function _userValidation() {
      $("#UserPassword").attr('data-fv-notempty', false);

      try {
        $("form#UserAddForm,form#UserProfileForm,form#UserResetForm,form#UserResetPasswordForm").formValidation({
          framework: 'bootstrap',
          excluded: ':disabled',
          //excluded: [':disabled', ':hidden', ':not(:visible)'],
          icon: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
          },
          // default email validations accepts a@b as valid, so do our own.  all other validations are defaults
          fields: {
            "data[User][username]": {
              validators: {
                stringLength: {
                  message: 'Atleast 4 charaters long.',
                  min: 4
                },
                remote: {
                  enabled: false,
                  message: 'Already exists',
                  url: '/users/users/is_username_available',
                  type: 'POST',
                  delay: 2000 // Send Ajax request every 2 seconds
                }
              }
            },
            "data[User][mobile]": {
              validators: {
                numeric: {
                  message: 'Please enter a number'
                }
              }
            },
            "data[User][country_id]": {
              validators: {
                notEmpty: {
                  message: 'Please enter a value'
                }
              }
            },
            "data[User][email]": {
              validators: {
                regexp: {
                  regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$',
                  message: 'Please enter a valid email address'
                },
                remote: {
                  enabled: false,
                  message: 'Already exists',
                  url: '/users/users/is_email_available',
                  type: 'POST',
                  delay: 2000 // Send Ajax request every 2 seconds
                }
              }
            },
            "data[User][password]": {
              validators: {
                //regexp: {
                //  //regexp: '((\d)([a-zA-Z0-9]@#$%).{6,20})',
                //  regexp: '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{6,20})',
                //  message: 'Required.'
                //}
                callback: {
                  message: 'Please enter a secured password',
                  callback: function callback(value, validator, $field) {
                    //console.log('=  =  =  =  =  =  =  =  =  =  =  =  ');
                    UserPasswordPopup._pwd_popup(value, $field);
                    //console.log($field);
                    //console.log(App.users._pwd_err);
                    //console.log('=  =  =  =  =  =  =  =  =  =  =  =  ');
                    if (UserPasswordPopup._pwd_err > 0) {
                      return {
                        'valid': false,
                        'message': 'Please enter a secured password'
                      };
                    } else {
                      return {
                        'valid': true,
                        'message': 'ok'

                      };
                    }
                  }
                }
              }
            },
            "data[User][verify_password]": {
              validators: {
                identical: {
                  field: 'data[User][password]',
                  message: 'The password you have entered does not match the one above. Please retry your password.'
                }
              }
            }
            //}).on('input', '[id="UserUsername"]', function (e) {
            // $(e.target)  --> The form instance
            // $(e.target).data('formValidation')
            //              --> The FormValidation instance

            // data.field   --> The field name
            // data.element --> The new field element
            // data.options --> The new field options
            //var fv = $(e.target).data('formValidation');
            //
            //console.log(fv.getInvalidFields());
            //
            //e.preventDefault();

          } }).on('focus change keyup', '[id="UserPassword"]', function (e) {
          $("form#UserAddForm,form#UserProfileForm,form#UserResetForm,form#UserResetPasswordForm").formValidation('revalidateField', 'data[User][password]');

          //var $field = $(this);
          //var value = $(this).val();
          //// Some instances you can use are
          //var $form = $(e.target),        // The form instance
          //  fv = $(e.target).data('formValidation'); // FormValidation instance
          //if (value.length) {
          //  //console.info(value);
          //  $("form#UserAddForm,form#UserProfileForm,form#UserResetForm,form#UserResetPasswordForm").formValidation('revalidateField', 'data[User][password]');
          //} else {
          //  console.info('null value');
          //  var $p = App.users._pwd_popup_instance();
          //  App.users._pwd_popup_content();
          //  $p.popover('show');
          //}

          //if(1){

          //  App.users._pwd_popup();
          //}else{
          //  var $p = App.users._pwd_popup_instance();
          //  App.users._pwd_popup_content();
          //  $p.popover('show');
          //}
          e.preventDefault();
          //}).on('input', '[id="UserPassword"]', function (e) {
          //  App.users._pwd_popup();
          //  e.preventDefault();
        }).on('err.validator.fv', function (e, data) {
          /**
           * show only one message
           * http://formvalidation.io/examples/showing-one-message/
           */
          // $(e.target)    --> The field element
          // data.fv        --> The FormValidation instance
          // data.field     --> The field name
          // data.element   --> The field element
          // data.validator --> The current validator name

          data.element.data('fv.messages')
          // Hide all the messages
          .find('.help-block[data-fv-for="' + data.field + '"]').hide()
          // Show only message associated with current validator
          .filter('[data-fv-validator="' + data.validator + '"]').show();
        }).on('success.form.fv', function (e) {
          console.log('form submitted');
          return true;
        });

        $('form#UserAddForm').formValidation('enableFieldValidators', 'data[User][username]', true, 'remote');
        $('form#UserAddForm').formValidation('enableFieldValidators', 'data[User][email]', true, 'remote');
      } catch (e) {
        console.error(e.message);
      }
    }
  },
  //hAr4$2
  file_manager: {
    init: function init() {
      // application-wide code
    }
  },
  resumes: {
    init: function init() {
      // controller-wide code
    },

    index: function index() {}
  },
  values_exercise: {
    init: function init() {}
  },
  ve_values: {
    init: function init() {},
    index: function index() {
      $('#myModal').modal({
        keyboard: true,
        show: false
      });
      // $('#myModal').on('hidden.bs.modal', function (e) {
      // 	console.log('hidden.bs.modal fired...');
      // });

      $("#confirmed-delete").on("click", function (e) {
        $('#VeValueClearForm').submit();
      });
    },
    welcome: function welcome() {
      $("form#VeValueEditForm").validate({
        rules: {
          "data[VeValue][terms]": {
            required: true
          }
        },
        messages: {},
        errorPlacement: function errorPlacement(error, element) {
          error.appendTo($(element.parent()));
        },
        submitHandler: function submitHandler(form) {
          //$('input[type="submit"]').attr('disabled','disabled');
          form.submit();
        },
        success: function success(label) {
          label.html("&nbsp;").addClass("checked");
        }
      });
    }
  },
  ve_user_past_current_roles: {
    init: function init() {
      $("form#VeUserPastCurrentRoleEditForm").validate({
        rules: {
          "data[VeUserPastCurrentRole][title]": {
            required: true,
            maxlength: 70
          },
          "data[VeUserPastCurrentRole][role]": {
            required: true,
            maxlength: 30
          },
          "data[VeUserPastCurrentRole][company]": {
            required: true,
            maxlength: 70
          },
          "data[VeUserPastCurrentRole][description]": {
            maxlength: 100
          },
          "data[VeUserPastCurrentRole][industry]": {
            required: true,
            maxlength: 40
          },
          "data[VeUserPastCurrentRole][start_date]": {
            linkedinDate: true,
            maxlength: 7
          },
          "data[VeUserPastCurrentRole][end_date]": {
            linkedinDate: true,
            maxlength: 7
          },
          "data[VeUserPastCurrentRole][primary_reason_for_leaving]": {
            maxlength: 500
          },
          "data[VeUserPastCurrentRole][primary_reason_for_accepting_opportunity]": {
            maxlength: 500
          }
        },
        messages: {
          "data[VeUserPastCurrentRole][start_date]": {
            maxlength: "Enter MM/YYYY",
            linkedinDate: "Enter MM/YYYY"
          },
          "data[VeUserPastCurrentRole][end_date]": {
            maxlength: "Enter MM/YYYY",
            linkedinDate: "Enter MM/YYYY"
          }
        },
        errorPlacement: function errorPlacement(error, element) {
          error.appendTo($(element.parent().parent().find(".box")));
        },
        // showErrors: function(errorMap, errorList) {
        // 	// Clean up any tooltips for valid elements
        // 	$.each(this.validElements(), function (index, element) {
        // 		var $element = $(element);
        // 		$element.data("title", "")
        // 		.removeClass("error")
        // 		.tooltip("destroy");
        // 		$element.parent().parent().removeClass('has-error');
        // 	});

        // 	// Create new tooltips for invalid elements
        // 	$.each(errorList, function (index, error) {
        // 		var $element = $(error.element);
        // 		$element.tooltip("destroy")
        // 		.data({'title': error.message,'container':'body','placement':'right','trigger':'manual','template': '<div class="tooltip tooltip-error"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'})
        // 		.addClass("error")
        // 		.tooltip('show');
        // 		$element.parent().parent().addClass('has-error');
        // 	});
        // },
        submitHandler: function submitHandler(form) {
          // //$('input[type="submit"]').attr('disabled','disabled');
          form.submit();
        },
        success: function success(label) {
          label.html("&nbsp;").addClass("checked");
        }

      });
    },
    index: function index() {
      $('.popover_id').each(function () {
        var $this = $(this);
        $this.popover({
          trigger: 'click',
          // trigger:'hover',
          // delay: { show: 500, hide: 200 },
          container: 'body',
          placement: 'top',
          html: true,
          content: $this.find('.hidden').html()
        });
      });
    }
  },
  ve_user_future_roles: {
    init: function init() {
      $("form#VeUserFutureRoleEditForm").validate({
        rules: {
          "data[VeUserFutureRole][title]": {
            required: true,
            maxlength: 70
          },
          "data[VeUserFutureRole][role]": {
            required: true,
            maxlength: 30
          },
          "data[VeUserFutureRole][company]": {
            required: true,
            maxlength: 70
          },
          "data[VeUserFutureRole][description]": {
            maxlength: 500
          },
          "data[VeUserFutureRole][industry]": {
            required: true,
            maxlength: 100
          }
        },
        errorPlacement: function errorPlacement(error, element) {
          error.appendTo($(element.parent().parent().find(".box")));
        },
        // showErrors: function(errorMap, errorList) {
        // 	// Clean up any tooltips for valid elements
        // 	$.each(this.validElements(), function (index, element) {
        // 		var $element = $(element);
        // 		$element.data("title", "")
        // 		.removeClass("error")
        // 		.tooltip("destroy");
        // 		$element.parent().parent().removeClass('has-error');
        // 	});

        // 	// Create new tooltips for invalid elements
        // 	$.each(errorList, function (index, error) {
        // 		var $element = $(error.element);
        // 		$element.tooltip("destroy")
        // 		.data({'title': error.message,'container':'body','placement':'right','trigger':'manual','template': '<div class="tooltip tooltip-error"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'})
        // 		.addClass("error")
        // 		.tooltip('show');
        // 		$element.parent().parent().addClass('has-error');
        // 	});
        // },
        submitHandler: function submitHandler(form) {
          // //$('input[type="submit"]').attr('disabled','disabled');
          form.submit();
        },
        success: function success(label) {
          label.html("&nbsp;").addClass("checked");
        }
        // messages: {},
        // errorPlacement: function(error, element) {
        // 		error.appendTo($(element.parent().find(".jstatus")));
        // },
        // submitHandler: function(form) {
        // 	// //$('input[type="submit"]').attr('disabled','disabled');
        // 	   form.submit();
        // },
        // success: function(label) {
        // 	label.html("&nbsp;").addClass("checked");
        // }
      });
    },
    index: function index() {
      $('.popover_id').each(function () {
        var $this = $(this);
        $this.popover({
          trigger: 'click',
          // trigger:'hover',
          // delay: { show: 500, hide: 200 },
          container: 'body',
          placement: 'top',
          html: true,
          content: $this.find('.hidden').html()
        });
      });
    }
  },
  ve_user_criterias: {
    init: function init() {},
    select: function select() {
      // Turn all the select boxes into rating controls
      //$(".veUserCriterias .rating").rating({showCancel: false, cancelValue: null, startValue: null});
      //console.log("hello world");
      $(".starRating").rating({
        'theme': 'krajee-fa',
        'stars': 4,
        'size': 'xs',
        'min': 0,
        'max': 4,
        'step': 1,
        'showClear': false,
        'showCaption': false
      });

      $('#VeUserCriteriaCareerValuesCriteriaForm').submit(function () {
        // alert(field_count);
        var field_count = $('#VeUserCriteriaCareerValuesCriteriaForm .starRating').length;
        var criteria_set = [];

        for (var i = 0; i < field_count + 1; i++) {
          criteria_set[i] = '#VeUserCriteriaCareerValuesCriteriaForm #' + i + 'VeUserCriteriaStar';
        }
        // alert(criteria_set);
        // var criteria_count = criteria_set.length;
        var count = 0;
        var not_important = 0;
        var valuable = 0;
        var important = 0;
        var critical = 0;

        var index = 0;
        while (criteria_set[index]) {

          var select_id = criteria_set[index];
          // alert(select_id);
          var criteria = $(select_id).val();
          if (!isNaN(criteria)) {
            if (criteria == '1') {
              not_important += 1;
            }
            if (criteria == '2') {
              valuable += 1;
            }
            if (criteria == '3') {
              important += 1;
            }
            if (criteria == '4') {
              critical += 1;
            }
            if (criteria > 0) {
              count += 1;
            }
          }
          index++;
        }
        /*while*/

        // alert(count);
        var TROUBLESHOOT = "You have submitted  " + "\n" + not_important + " Not Important" + "\n" + valuable + " Valuable" + "\n" + important + " Important" + "\n" + critical + " critical" + "\n" + count + " Total" + "\n" + " click ok to submit." + "\n" + " click cancel to edit.";

        if (count < field_count) {
          alert("Please identify all of the following criteria.");
          return false;
        } else {
          //return confirm(TROUBLESHOOT);
          //$('input[type="submit"]').attr('disabled','disabled');
          return true;
          /*Submit*/
          // return false;
        }
      });
    },
    finalize: function finalize() {
      var Finalize = {
        min: 6,
        max: 10,
        num_selected: 0,
        Error: false,
        message: null
      };

      Finalize.documentReady = function () {
        Finalize.logic();
        Finalize.flash();

        Finalize.validateChange();
        Finalize.validateForm();
      };

      Finalize.validateChange = function () {
        // $( "input.points" ).on('change', function(){});
        $("#VeUserCriteriaFinalizeForm input[type=checkbox]").on('click', function () {
          Finalize.logic();
          Finalize.flash();
        });
      };

      Finalize.logic = function () {
        Finalize.count_selected();
        // console.log(Finalize.num_selected);
        if (Finalize.num_selected < Finalize.min || Finalize.num_selected > Finalize.max) {
          Finalize.Error = true;
          return false;
        } else {
          Finalize.Error = false;
          return true;
        }
      };

      Finalize.count_selected = function () {
        Finalize.num_selected = $("#VeUserCriteriaFinalizeForm input[type=checkbox]:checked").length;
        // console.log(Finalize.num_selected);
        return Finalize.num_selected;
      };

      Finalize.flash = function () {
        if (Finalize.Error) {
          $(".javascriptMessage").text('Please select between 6-10 values.');
          $(".javascriptMessage").css('color', 'red');
          // return false;
        } else {
          $(".javascriptMessage").text('You can go ahead.');
          $(".javascriptMessage").css('color', 'green');
          // return true;
        }
      };

      Finalize.validateForm = function () {
        $('#VeUserCriteriaFinalizeForm').submit(function () {
          if (Finalize.logic()) {
            //$('input[type="submit"]').attr('disabled','disabled');
            return true;
          } else {
            alert('Please select between 6-10 values.');
            return false;
          }
        });
      };

      Finalize.documentReady();
    },
    prioritize: function prioritize() {
      var Prioritize = {
        pointTotalAllowed: 100,
        Error: false,
        message: null
      };

      Prioritize.documentReady = function () {
        /*on load*/
        Prioritize.sum_points();
        Prioritize.flash();

        Prioritize.sum_points_on_change();
        Prioritize.validateForm();
      };

      Prioritize.sum_points_on_change = function () {
        $("input.points").on('change', function () {
          Prioritize.sum_points();
          Prioritize.flash();
        });
      };

      Prioritize.sum_points = function () {

        var pointTotalAllowed = Prioritize.pointTotalAllowed;
        var pointsTotalSelector = $("#VeUserCriteriaPriortizeForm input.points");
        var pointsTotalTarget = $("#VeUserCriteriaPriortizeForm .points_total");

        var pointsTotal = 0;
        var message = null;

        pointsTotalSelector.each(function (index) {
          var newVal = $(this).val();
          if (!$.isNumeric(newVal)) {
            newVal = 0;
          }
          pointsTotal += parseInt(newVal);
        });

        pointsTotalSelector.each(function (index) {
          // console.log( index + ": " + $( this ).val() );
          var newVal = $(this).val();
          // if(!$.isNumeric(newVal)){
          if (!Prioritize.naturalNumber(newVal)) {
            // console.info($.type(newVal));
            // console.log('naturalNumber works.');
            Prioritize.message = 'Enter integers (whole numbers).';
            Prioritize.Error = true;
            return false;
            /*to break the loop only*/
          }
          // if(!$.isNumeric(newVal)){ newVal = 0; }
          // pointsTotal += parseInt(newVal);
        });
        pointsTotalTarget.text(pointsTotal);

        if (Prioritize.Error) {} else if (pointsTotal === pointTotalAllowed) {
          Prioritize.Error = false;
          return true;
        } else {
          Prioritize.message = 'Please allocate all ' + Prioritize.pointTotalAllowed + ' points.';
          Prioritize.Error = true;
        }
        return false;
      };

      Prioritize.naturalNumber = function (value) {
        // if(value.length == 0) { return true; }
        if (value < 0) {
          return false;
        }
        var filter = new RegExp("(?:0|[1-9][0-9]*)"); //naturalNumber include 0
        return filter.test(value);
      };

      Prioritize.flash = function () {
        // console.log('after');
        if (Prioritize.Error) {
          // console.log('error');
          $(".javascriptMessage").text(Prioritize.message);
          $(".javascriptMessage").css('color', 'red');
          Prioritize.Error = false;
        } else {
          $(".javascriptMessage").text('You can go ahead.');
          $(".javascriptMessage").css('color', 'green');
        }
      };

      Prioritize.description = function () {
        var object = $("#VeUserCriteriaPriortizeForm input.description");

        var empty = 0;
        object.each(function (e) {
          var newVal = $(this).val();
          if (newVal.length === 0) {
            empty++;
          }
          // alert(v);
        });

        if (empty === 0) {
          return true;
        }
        return false;
      };

      Prioritize.validateForm = function () {
        $('#VeUserCriteriaPriortizeForm').submit(function () {
          var okp = Prioritize.sum_points();
          // var okd = Prioritize.description();
          var okd = true;

          if (okp && okd) {
            //$('input[type="submit"]').attr('disabled','disabled');
            return true;
          } else if (!okp && !okd) {
            alert('Please allocate all 100 points and add descriptions to your values.');
            return false;
          } else if (!okp) {
            alert('Please allocate all 100 points.');
            return false;
          } else if (!okd) {
            alert('Please add descriptions to your values.');
            return false;
          }
          alert('Please allocate all 100 points and add descriptions to your values.');
          return false;
        });
      };

      Prioritize.documentReady();
    }
  },
  ve_user_minimum_requirements: {
    init: function init() {},
    save: function save() {
      //write code her this code will work on this page only
      // console.log(veMinimumRequirements);
      var VeUserMinimumRequirement = {
        veMinimumRequirements: veMinimumRequirements,
        message: null,
        Error: false,
        allCount: null,
        selectedValues: null,
        selectedCountUnique: null,
        selectedCount: null,
        descriptionCount: null
      };

      VeUserMinimumRequirement.documentReady = function () {
        VeUserMinimumRequirement.addRequirement();
        VeUserMinimumRequirement.flash();
        VeUserMinimumRequirement.changeHandler();
        VeUserMinimumRequirement.submitHandler();
      };

      VeUserMinimumRequirement.changeHandler = function () {
        $('form#VeUserMinimumRequirementForm').on('change', function () {
          VeUserMinimumRequirement.inputsMinReq();
          // VeUserMinimumRequirement.inputsFocusDesc();
          VeUserMinimumRequirement.inputsDesc();
          VeUserMinimumRequirement.validate();
          VeUserMinimumRequirement.flash();
        });
      };

      VeUserMinimumRequirement.submitHandler = function () {
        $('form#VeUserMinimumRequirementForm').submit(function () {
          VeUserMinimumRequirement.inputsMinReq();
          VeUserMinimumRequirement.inputsDesc();
          VeUserMinimumRequirement.validate();
          if (VeUserMinimumRequirement.Error) {
            alert(VeUserMinimumRequirement.message);
            return false;
          }
          // console.error("ERROR");
          return true;
        });
      };

      VeUserMinimumRequirement.addRequirement = function () {
        $("form#VeUserMinimumRequirementForm #addRequirementBtn").on('click', function () {
          var options = VeUserMinimumRequirement.veMinimumRequirements;
          var op = [];
          op[0] = '<option value="">N/A</option>';
          $.each(options, function (index, value) {
            op[index] = '<option value="' + index + '">' + value + '</option>';
          });
          op = op.join("");
          // console.log(op);
          var i = $("form#VeUserMinimumRequirementForm .ve_minimum_requirement_id").length;
          /*no spaces*/
          var requirement = '<div class="form-group row required"><div class="col-md-4 margin-bottom-16">' + '<label for="" class="visible-xs control-label">Minimum Requirement</label>' + '<select class="form-control ve_minimum_requirement_id" name="data[' + i + '][VeUserMinimumRequirement][ve_minimum_requirement_id]">' + op + '</select></div>' + '<div class="col-md-8">' + '<label for="" class="visible-xs control-label">Description</label>' + '<input type="text" id="' + i + 'VeUserMinimumRequirementDescription" maxlength="250" disabled="disabled" class="form-control description" name="data[' + i + '][VeUserMinimumRequirement][description]"></div></div>';
          // alert(i);
          $("form#VeUserMinimumRequirementForm .fieldsetArea").append(requirement);
        }).trigger('click');
      };

      VeUserMinimumRequirement.inputsMinReq = function () {
        var minReq = $("form#VeUserMinimumRequirementForm .ve_minimum_requirement_id");
        var allCount = minReq.length;

        var selectedValues = [];
        var selectedCount = 0;
        var descriptionCount = 0;
        // console.log(minReq);

        minReq.each(function (index, element) {
          // console.log(index + " | " + $(this).val());
          // console.log(element);
          // return false;
          // element == this
          var description = $(this).parent().parent().find('.description');

          var db_value = $(this).val();
          db_value = $.trim(db_value);
          if (db_value !== "" && $.isNumeric(db_value) && db_value > 0) {
            selectedCount++;
            selectedValues.push(db_value);
            // using index will skip index, resulting issues, use push to auto set index of array
            // selectedValues [index] = db_value;
            if (description.val().length > 0) {
              descriptionCount++;
            } else {
              description.addClass("error-message");
            }
            description.removeAttr("disabled");
            // VeUserMinimumRequirement.inputsFocusDesc();
            // $(element).parent().parent().find('.description').focus();
            // description.triggerHandler( "focus" );
          } else {
            /*#N/A from dropdown*/
            description.removeClass("error-message");
            description.attr("disabled", "disabled");
            // alert('n/a');
            // value.css('color:red');
          }
        });

        selectedValues = selectedValues.sort();
        // console.log(selectedValues);
        //$.unique only works on sorted values, issue caused on 2nd and 4th value, fixed with sort
        var selectedCountUnique = $.unique(selectedValues).length;
        VeUserMinimumRequirement.selectedCountUnique = selectedCountUnique;
        VeUserMinimumRequirement.selectedValues = selectedValues;
        VeUserMinimumRequirement.selectedCount = selectedCount;
        VeUserMinimumRequirement.descriptionCount = descriptionCount;
        VeUserMinimumRequirement.allCount = allCount;
      };
      VeUserMinimumRequirement.inputsFocusDesc = function () {
        //Create a new jQuery.Event object without the "new" operator.
        // var e = $.Event( "change" );
        // trigger an artificial click event
        // minReq.trigger( e );

        var minReq = $("form#VeUserMinimumRequirementForm .ve_minimum_requirement_id");
        minReq.on('change', function () {
          var description = $(this).parent().parent().find('.description');
          if ($(this).val() > 0) {
            /*!N/A*/
            console.info("focused");

            if (description.val().length > 0) {} else {
              description.addClass("error-message");
            }
            description.removeAttr('disabled').focus();
          } else {
            description.removeClass("error-message");
            description.attr("disabled", "disabled");
          }
        });
      };
      VeUserMinimumRequirement.inputsDesc = function () {
        /*.descrition text change handler*/
        var description = $('.description').on('change', function () {
          if ($(this).val().length > 0) {
            $(this).removeClass("error-message");
          } else {
            $(this).addClass("error-message");
          }
        });

        // return false;
      };
      VeUserMinimumRequirement.validate = function () {
        /*validate duplicates*/
        var retVal = true;
        if (VeUserMinimumRequirement.selectedCount === VeUserMinimumRequirement.selectedCountUnique) {
          retVal = retVal && true;
          if (VeUserMinimumRequirement.selectedCount === VeUserMinimumRequirement.descriptionCount) {
            retVal = retVal && true;
            VeUserMinimumRequirement.message = 'You can go ahead.';
          } else {
            VeUserMinimumRequirement.message = 'Please enter description for each minimum requirement.';
            retVal = retVal && false;
          }
        } else {
          VeUserMinimumRequirement.message = 'Please select unique minimum requirements.';
          retVal = retVal && false;
        }

        if (retVal) {
          VeUserMinimumRequirement.Error = false;
        } else {
          VeUserMinimumRequirement.Error = true;
        }

        // VeUserMinimumRequirement.troubleshoot();
      };
      VeUserMinimumRequirement.description = function () {};
      VeUserMinimumRequirement.flash = function () {
        if (VeUserMinimumRequirement.Error) {
          $(".javascriptMessage").text(VeUserMinimumRequirement.message);
          $(".javascriptMessage").css('color', 'red');
          // return false;
        } else {
          if (VeUserMinimumRequirement.message !== null) {
            $(".javascriptMessage").text(VeUserMinimumRequirement.message);
            $(".javascriptMessage").css('color', 'green');
            // return true;
          }
        }
      };

      VeUserMinimumRequirement.troubleshoot = function () {
        var log = '\n' + 'selectedValues:' + VeUserMinimumRequirement.selectedValues + '\n' + 'selectedCount:' + VeUserMinimumRequirement.selectedCount + '\n' + 'selectedCountUnique:' + VeUserMinimumRequirement.selectedCountUnique + '\n' + 'allCount:' + VeUserMinimumRequirement.allCount + '\n' + 'is error:' + VeUserMinimumRequirement.Error;
        console.log(log);
      };

      VeUserMinimumRequirement.documentReady();
    }
  },
  ve_user_criteria_evaluations: {
    init: function init() {
      $(".starRating").rating({
        'theme': 'krajee-fa',
        'stars': 5,
        'size': 'xs',
        'min': 0,
        'max': 5,
        'step': 1,
        'showClear': false,
        'showCaption': false
      });

      var VeUserCriteriaEvaluation = {
        Error: false,
        message: null
      };

      VeUserCriteriaEvaluation.documentReady = function () {
        VeUserCriteriaEvaluation.validateForm();
      };

      VeUserCriteriaEvaluation.validateForm = function () {
        $('#VeUserCriteriaEvaluationForm').submit(function () {
          var fieldSet = $('#VeUserCriteriaEvaluationForm input.starRating');
          var emptyFields = 0;
          fieldSet.each(function (index) {
            var newVal = $(this).val();
            //console.info($(this).attr('name'));
            //console.log(newVal);
            newVal = parseInt(newVal);
            if ($.isNumeric(newVal) && newVal > 0 && newVal <= 5) {
              //
            } else {
              emptyFields++;
            }
          });

          if (emptyFields > 0) {
            alert("Please evaluate all of the following criteria.");
            return false;
          } else {
            return true;
          }
        });
      };

      VeUserCriteriaEvaluation.documentReady();
    },

    past: function past() {},

    future: function future() {}
  },

  ve_user_minimum_requirement_evaluations: {
    init: function init() {},

    future: function future() {
      var VeUserMinimumRequirementEvaluations = {
        Error: false,
        message: null
      };

      VeUserMinimumRequirementEvaluations.documentReady = function () {
        VeUserMinimumRequirementEvaluations.validateForm();
      };

      VeUserMinimumRequirementEvaluations.validateForm = function () {
        $('#VeUserMinimumRequirementEvaluationForm').submit(function () {
          var fieldSet = $('#VeUserMinimumRequirementEvaluationForm select');
          // alert(fieldSet.length);

          var emptyFields = 0;
          fieldSet.each(function (index) {
            var newVal = $(this).val();
            newVal = parseInt(newVal);
            if (!$.isNumeric(newVal)) {
              emptyFields++;
            }
          });
          // alert(emptyFields);
          if (emptyFields > 0) {
            alert("Please evaluate all the minimum requirements.");
            return false;
          } else {
            // return confirm(TROUBLESHOOT);
            //$('input[type="submit"]').attr('disabled','disabled');
            return true;
            /*Submit*/
            // return false;
          }
        });
      };
      VeUserMinimumRequirementEvaluations.documentReady();
    }
  }
};

var UTIL = {
  exec: function exec(controller, act) {
    var ns = App,
        action = act === undefined ? "init" : act;

    if (controller !== "" && ns[controller] && typeof ns[controller][action] == "function") {
      ns[controller][action]();
    }
  },

  init: function init() {
    var body = document.body,
        plugin = body.getAttribute("data-plugin"),
        controller = body.getAttribute("data-controller"),
        action = body.getAttribute("data-action");

    UTIL.exec("common");
    UTIL.exec(plugin);
    UTIL.exec(controller);
    UTIL.exec(controller, action);
  }
};

$(document).ready(UTIL.init);