"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

//https://stackoverflow.com/questions/27441803/why-does-jshint-throw-a-warning-if-i-am-using-const

/*jshint esversion: 6 */

var Interactive = function () {
  function Interactive() {
    _classCallCheck(this, Interactive);

    this.msToHide = 5000;
    this.selector = {
      id: "section#flash-message",
      text: ".message-text"
    };

    this.template = "\n<section id=\"flash-message\" class=\"pshow\">\n  <div class=\"inner\">\n  <div class=\"toggle-buttons\">\n  <button id=\"hide-msg\" type=\"button\" class=\"\"><i class=\"fa fa-remove\"></i></button>\n  <button id=\"show-msg\" type=\"button\" class=\"\"><i class=\"fa fa-check\"></i></button>\n  </div>\n  <div class=\"alert alert-success\">\n  <i class=\"fa fa-check\"></i>\n  <span class=\"message-text\"></span>\n</div>\n</div>\n</section>";
  }

  _createClass(Interactive, [{
    key: "init",
    value: function init() {
      this.autoHide();
      this.clickHandler();
    }
  }, {
    key: "autoHide",
    value: function autoHide() {
      var _this = this;

      var msToHide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      msToHide = msToHide ? msToHide : this.msToHide;
      // console.log(msToHide);
      setTimeout(function () {
        $(_this.selector.id).removeClass('pshow').addClass('phide');
      }, msToHide);
    }
  }, {
    key: "clickHandler",
    value: function clickHandler() {
      var id = this.selector.id;
      $('#show-msg').on('click', function (e) {
        e.preventDefault();
        $(this).closest(id).removeClass('phide').addClass('pshow');
      });
      $('#hide-msg').on('click', function (e) {
        e.preventDefault();
        $(this).closest(id).removeClass('pshow').addClass('phide');
      });
    }
  }, {
    key: "show",
    value: function show() {
      var msToHide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      msToHide = msToHide ? msToHide : this.msToHide;
      if ($(this.selector.id).length) {
        $(this.selector.id).removeClass('phide').addClass('pshow');
        this.autoHide(msToHide);
        this.clickHandler();
      }
    }

    /**
     * make sure to hide #flash-message in css
     * @param options
     */

  }, {
    key: "update",
    value: function update(options) {
      if ($(this.selector.id).length) {} else {
        $('body').prepend(this.template);
      }
      $(this.selector.id).find('.alert').attr('class', 'alert alert-' + options.alert);
      $(this.selector.id).find($(this.selector.text)).html(options.text);
      this.show(5000);
    }
  }, {
    key: "success",
    value: function success(msg) {
      this.update({
        text: msg,
        alert: 'success'
      });
    }
  }, {
    key: "error",
    value: function error(msg) {
      this.update({
        text: msg,
        alert: 'danger'
      });
    }
  }, {
    key: "warning",
    value: function warning(msg) {
      this.update({
        text: msg,
        alert: 'warning'
      });
    }
  }, {
    key: "info",
    value: function info(msg) {
      this.update({
        text: msg,
        alert: 'info'
      });
    }
  }]);

  return Interactive;
}();

var interactive = new Interactive();